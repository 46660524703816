<template>
    <p>{{timerCount.hours}}<br /> <span>HRS</span></p>
    <p>{{timerCount.minutes}} <br /><span>MINS</span></p>
    <p>{{timerCount.seconds}} <br /><span>SECS</span></p>
</template>

<script setup>
import { defineProps, onMounted, ref, onUnmounted, watch, computed } from "vue";
import { ConvertSectoDay } from "@/misc/formatter";

const props = defineProps({
  endDate: String,
  status: String,
  remainingTime: Number
})

const timer = ref({
    hours: 0,
    minutes: 0,
    seconds: 0
})

const counter = ref(props.remainingTime)

let interval;
let timerInterval;

const reloadTimer = () => {
  timerInterval = setInterval(() => {
    if(counter.value > 1) {
      counter.value = counter.value - 1
    } else if(counter.value <= 1) {
      clearInterval(timerInterval)
    }
  }, 1000)
}

const loadRemainingTime = () => {
  if(props.remainingTime && props.remainingTime > 0) {
    reloadTimer();
  }
}

const timerCount = computed(() => {
  if (props.remainingTime && props.remainingTime > 0) {
    const {
      day, hour, minutes, seconds
    } = ConvertSectoDay(parseInt(counter.value));

    return {
      hours: hour,
      minutes,
      seconds
    }
  } else {
    return timer.value
  }
})

const loadTimer = () => {
  interval = setInterval(() => {
    // Get the current date and time
    const now = new Date().getTime();
    let endTime = new Date(props.endDate).getTime();
    let distance = endTime - now;
    var seconds = Math.floor(distance / 1000) % 60;
    var minutes = Math.floor(distance / (1000 * 60)) % 60;
    var hours = Math.floor(distance / (1000 * 60 * 60));
    minutes = minutes < 10 ? `0${minutes}` : minutes
    hours = hours < 10 ? `0${hours}` : hours
    seconds = seconds < 10 ? `0${seconds}` : seconds

    // Update the timer for this cardDetail
    if (endTime > now) {
      timer.value = {
        hours,
        minutes,
        seconds
      }
    }
  }, 1000);
}

onMounted(() => {
  if(props.status === 'In Progress') {
    loadRemainingTime()
  }
})

onUnmounted(() => {
  clearInterval(interval);
})

watch(() => [props.endDate, props.status, props.remainingTime], () => {
  clearInterval(interval)
  counter.value = props.remainingTime;
  timer.value = {
    hours: 0,
    minutes: 0,
    seconds: 0
  }
  
  if(props.status === 'In Progress') {
    loadRemainingTime()
  }
})
</script>